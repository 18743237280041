import React, {useEffect, useState} from 'react';
import CarnavalService from '../../api/backend/services/carnaval.service';

const HeaderRj = () => {
  const [carnival, setCarnival] = useState();

  async function load() {
    const response = await CarnavalService.list();
    const responsefiltred = response.find((carnaval) => carnaval.city === "Rio de Janeiro");
    setCarnival(responsefiltred);
  }
  useEffect(() => {
    load().then();
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      load().then();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
     <div className="header-odia">
      <div className="row-header-odia">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="items-menu">
                <div className="go-home">
                  <a
                    href="https://odia.ig.com.br/"
                    target="_blank"
                    title="Capa Jornal O Dia" rel="noreferrer"
                  >
                    Home
                  </a>
                </div>
                <div className="go-home">
                  <a
                    href="https://odia.ig.com.br/rio-de-janeiro"
                    target="_blank"
                    title="Rio de Janeiro" rel="noreferrer"
                  >
                    Rio de Janeiro
                  </a>
                </div>
                <div className="go-home">
                  <a
                    href="https://odia.ig.com.br/esporte"
                    target="_blank"
                    title="Esporte" rel="noreferrer"
                  >
                    Esporte
                  </a>
                </div>
                <div className="go-home">
                  <a
                    href="https://odia.ig.com.br/diversao"
                    target="_blank"
                    title="Diversão" rel="noreferrer"
                  >
                    Diversão
                  </a>
                </div>
              </div>
            </div>
          <div className="col-11 col-lg-4">
              <div className="logo-folia">
                <a
                  href="https://odia.ig.com.br/diversao/carnaval"
                  target="_blank" rel="noreferrer"
                >
                  <img
                    src="https://i0.statig.com.br/assets/imgs/o-dia-logo-carnaval.jpg"
                    alt="" className="logo-rj"
                  />
                </a>
              </div>
            </div>
          <div className="col-1 col-lg-1">
                <p className="btn-rj btn_live" style={{ display: carnival?.finished ? 'none' : 'block '}}>Ao vivo<span className="live-icon"></span></p>
            </div>
  
          <div className="col-12 col-lg-3">
            <div className="social-buttons none-desk">
              <a
                title="O Dia no Facebook"
                href="https://www.facebook.com/jornalodia/"
                target="_blank"
                className="social-buttons__button social-button social-button--facebook"
                aria-label="Facebook" rel="noreferrer"
                >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                title="O Dia no X"
                href="https://x.com/jornalodia"
                target="_blank"
                className="social-buttons__button social-button social-button--twitter"
                aria-label="twitter" rel="noreferrer"
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" enable-background="new 0 0 1668.56 1221.19" viewBox="0 0 1668.56 1221.19" id="twitter-x"><path d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z" transform="translate(52.39 -25.059)"></path></svg>
              </a>
              <a
                title="O Dia no Instagram"
                href="https://www.instagram.com/odiaonline/"
                target="_blank"
                className="social-buttons__button social-button social-button--instagram"
                aria-label="instagram" rel="noreferrer"
                >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                title="O Dia no Youtube"
                href="https://www.youtube.com/channel/UC6Z813ofOTQjsq0PPNoItbQ"
                target="_blank"
                className="social-buttons__button social-button social-button--youtube"
                aria-label="youtube" rel="noreferrer"
                >
                <i className="fab fa-youtube"></i>
              </a>
            </div>
            <div className="menu-toogle-h">
              <div className="menu-button-wrapper" for="">
                  <input type="checkbox" className="menu-button"></input>
                  <div className="icon-wrapper">
                  <div className="hamburger">
                      <input className="hamburger-input" type="checkbox"></input>
                      <span className="hamburger-line first"></span>
                      <span className="hamburger-line second"></span>
                      <span className="hamburger-line third"></span>
                  </div>
                  </div>
                  <div className="item-list">
                  <div>
                  <a className="a-link" 
                    href="https://odia.ig.com.br/"
                    target="_blank"
                    title="Capa Jornal O Dia" rel="noreferrer"
                  >
                    Home
                  </a>
                </div>
                <div>
                  <a className="a-link" 
                    href="https://odia.ig.com.br/rio-de-janeiro"
                    target="_blank"
                    title="Rio de Janeiro" rel="noreferrer"
                  >
                    Rio de Janeiro
                  </a>
                </div>
                <div>
                  <a className="a-link" 
                    href="https://odia.ig.com.br/esporte"
                    target="_blank"
                    title="Esporte" rel="noreferrer"
                  >
                    Esporte
                  </a>
                </div>
                <div>
                  <a className="a-link" 
                    href="https://odia.ig.com.br/diversao"
                    target="_blank"
                    title="Diversão" rel="noreferrer"
                  >
                    Diversão
                  </a>
                </div>
                
                <div className="social-buttons">
              <a
                title="O Dia no Facebook"
                href="https://www.facebook.com/odiajornal/"
                target="_blank"
                className="social-buttons__button social-button social-button--facebook"
                aria-label="Facebook" rel="noreferrer"
                >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                title="O Dia no X"
                href="https://x.com/jornalodia"
                target="_blank"
                className="social-buttons__button social-button social-button--twitter"
                aria-label="twitter" rel="noreferrer"
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" enable-background="new 0 0 1668.56 1221.19" viewBox="0 0 1668.56 1221.19" id="twitter-x"><path d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z" transform="translate(52.39 -25.059)"></path></svg>
              </a>
              <a
                title="O Dia no Instagram"
                href="https://www.instagram.com/odiaonline/"
                target="_blank"
                className="social-buttons__button social-button social-button--instagram"
                aria-label="instagram" rel="noreferrer"
                >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                title="O Dia no Youtube"
                href="https://www.youtube.com/channel/UC6Z813ofOTQjsq0PPNoItbQ"
                target="_blank"
                className="social-buttons__button social-button social-button--youtube"
                aria-label="youtube" rel="noreferrer"
                >
                <i className="fab fa-youtube"></i>
              </a>
            </div>
                </div>
                  </div>
              </div>
  
              </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default HeaderRj;